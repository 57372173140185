<script>
import MainButton from "@/components/helpers/MainButton.vue";
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";

export default {
  name: "CertificateOrderBlock",

  components: { ButtonRedirect, MainButton },

  emits: ["showInfo"],

  props: {
    application: {
      type: Object,
    },
  },
};
</script>

<template>
  <div class="certificate-order-block-container">
    <div class="certificate-order-block">
      <div class="certificate-order-block__content content">
        <h2>Справка о ведении деятельности в RC&nbsp;Group</h2>

        <template v-if="application === null">
          <p>
            Получите справку, подтверждающую вашу деятельность в RC&nbsp;Group.
            Срок обработки заявки 2 рабочих дня.
          </p>

          <div class="content__footer">
            <MainButton
              title="Заказать справку"
              color="dark"
              padding="14px 24px"
              @click="$router.push('/certificate-order')"
            />
          </div>
        </template>

        <template v-else-if="application.status === 'approved'">
          <p>
            Справка отправлена на вашу почту. Доступен повторный заказ справки.
          </p>

          <span class="divider" />

          <div class="content__footer">
            <span :class="`content__tag content__tag_${application.status}`">
              Справка выдана
            </span>

            <MainButton
              title="Заказать повторно"
              color="blue"
              @click="$router.push('/certificate-order')"
            />
          </div>
        </template>

        <template v-else-if="application.status === 'processing'">
          <p>Срок обработки заявки 2 рабочих дня.</p>

          <span class="divider" />

          <div class="content__footer">
            <span :class="`content__tag content__tag_${application.status}`">
              Заявка в обработке
            </span>
          </div>
        </template>

        <template v-else>
          <p>
            Ознакомьтесь с причиной отклонения и отредактируйте заявку, чтобы
            отправить на повторную проверку.
          </p>

          <span class="divider" />

          <div class="content__footer">
            <span :class="`content__tag content__tag_${application.status}`">
              Заявка отклонена
            </span>

            <MainButton
              title="Заказать повторно"
              color="blue"
              @click="$router.push('/certificate-order')"
            />

            <ButtonRedirect
              class="content__button-redirect"
              text="Подробнее"
              arrow-position="right"
              @click="$emit('showInfo')"
            />
          </div>
        </template>
      </div>

      <img src="../../assets/images/certificate_order.svg" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.certificate-order-block-container {
  container: certificate-order-block / inline-size;

  @container certificate-order-block (max-width: 720px) {
    .certificate-order-block {
      grid-template-columns: 1fr;

      > img {
        display: none;
      }
    }
  }
}

.certificate-order-block {
  display: grid;
  grid-template-columns: 1fr 200px;
  background-color: $light-primary;
  border-radius: 8px;
  max-width: 820px;
  overflow: hidden;

  .content {
    padding: 32px;

    > h2 {
      @include title-3;
      color: $dark-primary;
      margin-bottom: 12px;
    }

    > p {
      @include body-1;
      color: $dark-third;
    }

    .divider {
      margin: 24px 0 0;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 24px;
    }

    &__tag {
      padding: 8px 12px;
      border-radius: 8px;
      @include text-2;
      color: $dark-primary;

      // TODO: переписать на статусы
      &_approved {
        background-color: $services-fuel;
      }

      &_declined {
        background-color: $services-market;
      }

      &_processing {
        background-color: $services-online;
      }
    }

    &__button-redirect {
      margin-left: auto;
    }
  }

  > img {
    margin-top: auto;
    pointer-events: none;
  }
}

@media (max-width: 900px) {
  .certificate-order-block {
    .content {
      padding: 24px;

      .divider {
        margin-top: 20px;
      }

      &__footer {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 576px) {
  .certificate-order-block {
    .content {
      padding: 24px 16px;

      > h2 {
        margin-bottom: 8px;
      }

      .divider {
        display: none;
      }

      &__footer {
        margin-top: 16px;
      }

      &__button-redirect {
        margin-left: 0;
      }
    }
  }
}
</style>
