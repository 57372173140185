<script>
import { mapActions, mapState } from "vuex";
import { DOCUMENT_SECTIONS } from "@/package/const/document-sections.js";
import DocumentSectionPreview from "@/components/documents/DocumentSectionPreview.vue";
import CertificateOrderBlock from "@/components/documents/CertificateOrderBlock.vue";
import CertificateInfoModal from "@/components/documents/CertificateInfoModal.vue";

export default {
  components: {
    CertificateOrderBlock,
    DocumentSectionPreview,
    CertificateInfoModal,
  },

  data() {
    return {
      DOCUMENT_SECTIONS: DOCUMENT_SECTIONS,
      isShowApplication: false,
      isShowInfoModal: false,
      affordableTariffs: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      application: (state) => state.application.application,
    }),
  },

  methods: {
    ...mapActions({
      checkLatestApplication: "application/checkLatestApplication",
    }),
  },

  mounted() {
    this.checkLatestApplication().then(() => {
      this.isShowApplication = this.affordableTariffs.includes(
        this.user.tariff.code
      );
    });

    if (
      (this.user.tariff?.code !== "vip_d" &&
        this.user.tariff?.code !== "vip_gold_d") ||
      this.user.tariff === null
    ) {
      this.DOCUMENT_SECTIONS = DOCUMENT_SECTIONS.filter((section) => {
        return section.id !== "tsp";
      });
    }
  },
};
</script>

<template>
  <div class="documents default-content-padding">
    <CertificateOrderBlock
      v-if="isShowApplication"
      class="documents__certificate-order-block"
      :application="application"
      @showInfo="isShowInfoModal = true"
    />

    <CertificateInfoModal
      :is-show-modal="isShowInfoModal"
      :comment="application?.comment || ''"
      @close="isShowInfoModal = false"
    />

    <div class="documents__sections">
      <DocumentSectionPreview
        v-for="(section, index) in DOCUMENT_SECTIONS"
        :key="index"
        :section="section"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.documents {
  padding-bottom: 48px;

  &__certificate-order-block {
    margin-bottom: 64px;
  }

  &__sections {
    display: grid;
    grid-template-columns: repeat(auto-fill, 390px);
    gap: 40px;
    max-width: 820px;

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, minmax(280px, 328px));
    }
  }
}
</style>
