<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";

export default {
  components: { ButtonRedirect },

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sectionImagePath() {
      return require(`../../assets/images/documents/${this.section.id}.png`);
    },
  },
};
</script>

<template>
  <RouterLink
    :to="{ path: `documents/${section.id}` }"
    class="document-section-preview"
  >
    <h4 class="document-section-preview__title">{{ section.title }}</h4>
    <p class="document-section-preview__description">
      {{ section.description }}
    </p>

    <ButtonRedirect
      class="document-section-preview__button-redirect"
      :text="
        section.id !== 'rdo'
          ? 'Список документов'
          : 'Презентации и инструкции для работы по направлению РДО'
      "
      :arrow-position="'right'"
      :button-style="'bold'"
    />

    <div class="document-section-preview__img">
      <img :src="sectionImagePath" alt="" />
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
.document-section-preview {
  background: $light-primary;
  border-radius: 8px;
  min-height: 200px;
  padding: $space-xl 148px $space-xl $space-m;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  text-decoration: none;

  &__title {
    @include title-3;
    color: $dark-primary;
    margin-bottom: $space-s;
  }

  &__description {
    @include body-1;
    color: $dark-primary;
    margin-bottom: $space-m;
  }

  &__button-redirect {
    margin-top: auto;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 140px;
    display: flex;
    align-items: flex-end;

    img {
      width: 100%;
      max-height: 200px;
    }
  }

  @media (max-width: 576px) {
    padding: $space-l 128px $space-l $space-m;
  }
}
</style>
