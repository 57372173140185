<script>
import MainModal from "@/package/components/MainModal.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "CertificateInfoModal",

  emits: ["close"],

  components: { MainButton, MainModal },

  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },

    comment: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <MainModal
    class="certificate-info-modal"
    :show-modal="isShowModal"
    @close="$emit('close')"
  >
    <h1>Причина отклонения</h1>

    <p>{{ comment }}</p>

    <MainButton
      :title="'Понятно'"
      :color="'dark'"
      :padding="'14px 24px'"
      @click="$emit('close')"
    />
  </MainModal>
</template>

<style lang="scss" scoped>
.certificate-info-modal {
  ::v-deep .main-modal__container {
    max-width: 500px;
    width: 100%;
  }

  h1 {
    @include title-3;
    color: $dark-primary;
    margin-bottom: 24px;
  }

  p {
    @include body-1;
    color: $dark-primary;
    margin-bottom: 24px;
    word-break: break-word;
  }
}
</style>
