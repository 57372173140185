export const DOCUMENT_SECTIONS = [
  {
    id: "presents",
    title: "Презентационные материалы",
    description:
      "Инструкции по работе, материалы для презентации продуктов и услуг",
  },
  {
    id: "marketing",
    title: "План агентских вознаграждений",
    description:
      "Гайды и инструкции по стратегии заработка и получению дохода ",
  },
  {
    id: "licenses",
    title: "Лицензии",
    description: "Государственная аккредитация и сертификаты RC\u00A0Group",
  },
  {
    id: "legal",
    title: "Юридические документы",
    description: "Договоры, акты, соглашения",
  },
  {
    id: "tsp",
    title: "Работа с ТСП",
    description: "Материалы для организации работы с ТСП",
  },
  {
    id: "rcpos",
    title: "Инструкции и документы для работы с RC POS",
    description: "",
  },
  {
    id: "rdo",
    title: "Русское деловое общество",
    description: "",
  },
];
